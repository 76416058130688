<template>
  <!--
    The view for the PhotoTrapDialog-component.
    Used to communicate with the Phototraps.
  -->
  <div class="installationDetail_Phototrap">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <div class="card">
        <Portlet
          :title="$t('installationDetailPhototrapView.phototrap')"
          icon="camera"
        >
          <PhotoTrapDialog :installation-id="installationId" />
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailPhototrap",
  components: {
    PhotoTrapDialog: () => import('@/components/PhotoTrap/PhotoTrapDialog.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationObject: null,
      loading: true,
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationDetailPhototrapView.phototrap')
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>